import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import CarouselHero from '../components/CarouselHero'
import Layout from '../components/Layout'
import RelatedResources from '../components/RelatedResources'
import RelatedSolutions from '../components/RelatedSolutions'
import ResourceSearch from '../components/ResourceSearch'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import { booleanFilter } from '../utils/helpers'

const ResourceOverviewPage = (
  props: PageProps<Queries.ResourcesOverviewPageQuery>,
) => {
  const pageData = props?.data?.contentstackPageResourcesOverview

  const topicFilters =
    props?.data?.contentstackPageResourcesOverview?.resource_search[0]
      ?.topic_filters

  const rolesFilters =
    props?.data?.contentstackPageResourcesOverview?.resource_search[0]
      ?.role_based_filters

  const allFeaturedResourceCards =
    props?.data?.allFeaturedResourceCards?.nodes || []

  const {
    data: { allPages, allResources },
  } = props
  const allPagesCards = allPages?.edges.map(({ node }) => ({
    ...node,
    roles_bookmarked:
      rolesFilters
        ?.filter((topic) =>
          topic?.bookmarked_items?.find(
            (bookmarked_item) => bookmarked_item?.id === node.objectID,
          ),
        )
        .filter(booleanFilter)
        .map(({ slug }) => slug) ?? [],
    topic_bookmarked:
      topicFilters
        ?.filter((topic) =>
          topic?.bookmarked_items?.find(
            (bookmarked_item) => bookmarked_item?.id === node.objectID,
          ),
        )
        .filter(booleanFilter)
        .map(({ slug }) => slug) ?? [],
    short_description: node.short_description?.replace(/<[^>]+>/g, ''),
    publication_date: parseInt(
      (
        (node.publish_details?.time
          ? new Date(node.publish_details.time)
          : new Date()
        ).getTime() / 1000
      ).toFixed(0),
    ),
  }))
  const allResourcesCards = allResources?.edges.map(
    ({ node: { resource, ...node } }) => ({
      ...node,
      ...resource?.[0],
      roles_bookmarked:
        rolesFilters
          ?.filter((topic) =>
            topic?.bookmarked_items?.find(
              (bookmarked_item) => bookmarked_item?.id === node.objectID,
            ),
          )
          .filter(booleanFilter)
          .map(({ slug }) => slug) ?? [],
      topic_bookmarked:
        topicFilters
          ?.filter((topic) =>
            topic?.bookmarked_items?.find(
              (bookmarked_item) => bookmarked_item?.id === node.objectID,
            ),
          )
          .filter(booleanFilter)
          .map(({ slug }) => slug) ?? [],
      publication_date: parseInt(
        (
          (node.publish_details?.time
            ? new Date(node.publish_details.time)
            : new Date()
          ).getTime() / 1000
        ).toFixed(0),
      ),
    }),
  )

  const allCards = [...(allPagesCards ?? []), ...(allResourcesCards ?? [])]

  const breadcrumbLinks = [{ text: pageData?.title || '' }]

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      {pageData?.page_header?.[0] ? (
        <CarouselHero
          headline={pageData?.page_header?.[0]?.headline}
          locale={pageData?.publish_details?.locale}
          resources={pageData?.page_header?.[0]?.resources}
          type={pageData?.page_header?.[0]?.type}
        />
      ) : null}
      {pageData?.resource_search?.[0] ? (
        <ResourceSearch
          content={{ ...pageData?.resource_search?.[0], allCards }}
        />
      ) : null}
      {pageData?.resource_grid?.[0] ? (
        <RelatedResources
          content={{
            related_resources: allFeaturedResourceCards,
            ...pageData?.resource_grid?.[0],
          }}
        />
      ) : null}
      {pageData?.solutions_card_carousel?.[0] ? (
        <RelatedSolutions
          relatedSolutions={pageData?.solutions_card_carousel?.[0]}
        />
      ) : null}
    </Layout>
  )
}

export const Head = ({
  data,
}: HeadProps<Queries.ResourcesOverviewPageQuery>) => (
  <>
    <SchemaMarkup
      data={data.contentstackPageResourcesOverview?.schema_markup}
    />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageResourcesOverview?.title}
    />
  </>
)

export default ResourceOverviewPage

export const data = graphql`
  query ResourcesOverviewPage($pageId: String, $locale: String) {
    contentstackPageResourcesOverview(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      url
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        headline
        type
        resources {
          id
          url
          publish_details {
            locale
          }
          resource {
            headline
            short_description
            feature_image {
              description
              imgixImage {
                gatsbyImageData(width: 1140, imgixParams: { q: 65 })
              }
            }
          }
        }
      }
      resource_grid {
        ...RelatedResourcesSection
      }
      resource_search {
        id
        filter_group_headline
        search_label_text
        load_more_text
        max_more_loaded
        filter_all_text
        results_found_text
        topic_filters_default_text
        role_based_filters_default_text
        role_based_filters {
          id
          title
          slug
          bookmarked_items {
            ... on Contentstack_page_resource {
              id
              url
              created_at
              publish_details {
                time
              }
              metadata {
                page_title
              }
            }
            ... on Contentstack_page {
              id
              publish_details {
                time
              }
            }
          }
        }
        topic_filters {
          id
          title
          slug
          bookmarked_items {
            ... on Contentstack_page_resource {
              id
              url
              created_at
              publish_details {
                time
              }
              metadata {
                page_title
              }
            }
            ... on Contentstack_page {
              id
              publish_details {
                time
              }
            }
          }
        }
        promoted_resources {
          id
          url
          resource {
            ...ResourceCardFragment
          }
        }
      }
      solutions_card_carousel {
        ...FeaturedProgramsSection
      }
    }
    allFeaturedResourceCards: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: { elemMatch: { is_featured: { eq: "Yes" } } }
      }
      sort: { fields: resource___publication_date, order: DESC }
      limit: 15
    ) {
      nodes {
        url
        title
        resource {
          ...ResourceCardFragment
        }
      }
    }
    allResources: allContentstackPageResource(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          objectID: id
          title
          page_url: url
          resource {
            headline
            short_description
            image: feature_image {
              description
              imgixImage {
                url
                gatsbyImageData(
                  width: 350
                  placeholder: BLURRED
                  imgixParams: {}
                )
              }
            }
          }
          publish_details {
            time
          }
        }
      }
    }
    allPages: allContentstackPage(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      edges {
        node {
          objectID: id
          page_url: url
          headline: hero_text
          short_description: subhero_text
          image: hero_image {
            description
            imgixImage {
              url
              gatsbyImageData(width: 350, placeholder: BLURRED, imgixParams: {})
            }
          }
          publish_details {
            time
          }
        }
      }
    }
  }
`
