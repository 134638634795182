import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { HourglassEmpty } from '@material-ui/icons'
import Close from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import algoliasearch from 'algoliasearch'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  BasicDoc,
  InfiniteHitsProvided,
  SearchBoxProvided,
  StateResultsProvided,
} from 'react-instantsearch-core'
import {
  Configure,
  connectInfiniteHits,
  connectRefinementList,
  connectSearchBox,
  connectStateResults,
  Index,
  InstantSearch,
} from 'react-instantsearch-dom'

import { useLocale } from '../LocaleContext'
import FeatureCardVertical from '../UI/FeatureCardVertical'
import Wrapper from '../UI/Wrapper'
import { ResourceSearchSection, ResultsSection, SearchButton } from './styles'

interface BookmarkCard {
  title: string
  objectID: string
  page_url: string
  headline: string
  short_description: string
  image: {
    description: string
    imgixImage: object
  }
  roles_bookmarked: string[]
  topic_bookmarked: string[]
  publication_date: number
}

interface Content {
  load_more_text: string
  filter_group_headline: string
  search_label_text: string
  topic_filters: {
    id: string
    title: string
  }[]
  role_based_filters: {
    id: string
    title: string
  }[]
  results_found_text: string
  promoted_resources: any[]
  allCards: BookmarkCard[]
}

interface ResourceSearchProps {
  content: Content
}

const HITS_PER_PAGE = 9

const updateQuery = (param: string, value: unknown): void => {
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = urlSearchParams.entries()

    const newURL = new URL(window.location.href)

    const paramArray = Array.from(params)
    if (paramArray.length) {
      let query = '?'
      if (value) {
        query = `?${param}=${value}&`
      }
      paramArray.forEach((e) => {
        if (e[0] !== param) {
          query += `${e[0]}=${e[1]}&`
        }
      })
      newURL.search = query.slice(0, -1)
    } else {
      newURL.search = `?${param}=${value}`
    }
    window.history.replaceState({ path: newURL.href }, '', newURL.href)
  }
}

const getParam = (param: string): string => {
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    return params[param] ?? ''
  }
  return ''
}

const RefineTopicDropdown = ({
  content,
  refine,
  defaultRefinement,
  shrinkLabel,
  setShrinkLabel,
  labelRef,
}) => (
  <FormControl className="MuiTextField-root textField textFieldHalf">
    <InputLabel
      htmlFor="filterTopic"
      ref={labelRef}
      shrink={shrinkLabel}
      variant="filled"
    >
      {content.topic_filters_default_text}
    </InputLabel>
    <Select
      defaultValue={defaultRefinement[0]}
      id="filterTopic"
      name="topic"
      onChange={(e) => {
        updateQuery('topic', e.target.value)
        refine(e.target.value)
        setShrinkLabel(Boolean(e.target.value))
      }}
      value={getParam('topic')}
      variant="filled"
    >
      <MenuItem selected={defaultRefinement[0] === ''} value="">
        {content.filter_all_text}
      </MenuItem>
      {content?.topic_filters?.map((topic: any) => (
        <MenuItem
          key={topic.id}
          selected={defaultRefinement[0] === topic.slug}
          value={topic.slug}
        >
          {topic.title}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

const RefineRoleDropdown = ({
  content,
  refine,
  defaultRefinement,
  shrinkLabel,
  setShrinkLabel,
  labelRef,
}) => (
  <FormControl className="MuiTextField-root textField textFieldHalf">
    <InputLabel
      htmlFor="filterRole"
      ref={labelRef}
      shrink={shrinkLabel}
      variant="filled"
    >
      {content.role_based_filters_default_text}
    </InputLabel>
    <Select
      defaultValue={defaultRefinement[0]}
      id="filterRole"
      name="role"
      onChange={(e) => {
        updateQuery('role', e.target.value)
        refine(e.target.value)
        setShrinkLabel(Boolean(e.target.value))
      }}
      value={getParam('role')}
      variant="filled"
    >
      <MenuItem selected={defaultRefinement[0] === ''} value="">
        {content.filter_all_text}
      </MenuItem>
      {content?.role_based_filters?.map((role: any) => (
        <MenuItem
          key={role.id}
          selected={defaultRefinement[0] === role.slug}
          value={role.slug}
        >
          {role.title}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

const CustomTopicsRefine = connectRefinementList(RefineTopicDropdown)
const CustomRolesRefine = connectRefinementList(RefineRoleDropdown)

const SearchBox = ({ refine, currentRefinement, isSearchStalled, content }) => {
  const [searchKeyword, setSearchKeyword] = useState(getParam('searchKeyword'))
  const [shrinkTopicLabel, setShrinkTopicLabel] = useState(
    Boolean(getParam('topic')),
  )
  const [shrinkRoleLabel, setShrinkRoleLabel] = useState(
    Boolean(getParam('role')),
  )
  const topicLabel = useRef<HTMLLabelElement>(null)
  const roleLabel = useRef<HTMLLabelElement>(null)

  useEffect(() => {
    topicLabel?.current?.setAttribute('data-shrink', String(shrinkTopicLabel))
    roleLabel?.current?.setAttribute('data-shrink', String(shrinkRoleLabel))
  }, [shrinkTopicLabel, shrinkRoleLabel])

  const onClickClearButton = (e) => {
    setSearchKeyword('')
    refine('')
    updateQuery('searchKeyword', '')
  }

  return (
    <form
      className="searchForm"
      id="searchForm"
      noValidate
      onSubmit={(e) => {
        e.preventDefault()
        updateQuery('searchKeyword', searchKeyword)
        refine(searchKeyword)
      }}
    >
      <Grid className="keywordInputContainer" container>
        <TextField
          className="textField keywordInput"
          id="searchKeyword"
          label={content.search_label_text}
          name="searchKeyword"
          onChange={(e) => setSearchKeyword(e.target.value)}
          value={searchKeyword}
          variant="filled"
        />
        {currentRefinement ? (
          <span className="clearContainer">
            <button
              className="clearButton"
              onClick={onClickClearButton}
              type="button"
            >
              <Close />
            </button>
          </span>
        ) : null}
        <SearchButton disabled={isSearchStalled} type="submit">
          {isSearchStalled ? (
            <>
              <HourglassEmpty />
              <span className="sr-only">Loading</span>
            </>
          ) : (
            <>
              <SearchIcon />
              <span className="sr-only">Search</span>
            </>
          )}
        </SearchButton>
      </Grid>
      <Typography className="filterSubhead" component="h3" variant="h3">
        {content.filter_group_headline}
      </Typography>
      <Grid className="filterContainer" container>
        <CustomTopicsRefine
          attribute="topics"
          content={content}
          defaultRefinement={[getParam('topic')]}
          labelRef={topicLabel}
          setShrinkLabel={setShrinkTopicLabel}
          shrinkLabel={shrinkTopicLabel}
        />
        <CustomRolesRefine
          attribute="roles"
          content={content}
          defaultRefinement={[getParam('role')]}
          labelRef={roleLabel}
          setShrinkLabel={setShrinkRoleLabel}
          shrinkLabel={shrinkRoleLabel}
        />
      </Grid>
    </form>
  )
}

interface HitCountProps extends StateResultsProvided {
  content: Content
}

const HitCount = connectStateResults<HitCountProps>(
  ({ searchResults, content }) => {
    const hitCount = searchResults && searchResults.nbHits

    return hitCount > -1 ? (
      <Typography className="headline" component="h2" variant="h2">
        {`${hitCount} ${content.results_found_text}`}
      </Typography>
    ) : null
  },
)

interface HitsProps extends InfiniteHitsProvided<BasicDoc> {
  content: Content
}

const Hits: FC<HitsProps> = ({ hits, hasMore, refineNext, content }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const topic = getParam('topic')
  const role = getParam('role')

  const noFilter = !getParam('searchKeyword') && !topic && !role
  const noPressRealeasePromotedResources = content.promoted_resources?.filter(
    ({ resource }) =>
      !resource[0]?.resource_type?.[0]?.title
        ?.toLowerCase()
        .includes('press release'),
  )

  const bookmarkedResources = content.allCards
    .filter(
      (resourceCard) =>
        (topic || role) &&
        (!topic || resourceCard.topic_bookmarked?.includes(topic)) &&
        (!role || resourceCard.roles_bookmarked?.includes(role)),
    )
    .sort((cardA, cardB) => cardB.publication_date - cardA.publication_date)
  const filteredHits = hits.filter(
    (hit) =>
      !bookmarkedResources.some(
        (resource) => resource.objectID === hit.objectID,
      ),
  )

  const hitsWithBookmarks = [...bookmarkedResources, ...filteredHits].filter(
    ({ title }) => !title?.toLowerCase().includes('press release'),
  )

  const hitsByPageUrl = Object.values(
    hitsWithBookmarks.reduce((acc, hit) => {
      if (!acc[hit.page_url]) {
        acc[hit.page_url] = hit
      }

      return acc
    }, {}),
  )

  const hasMorePages =
    hasMore || hitsWithBookmarks.length > pageNumber * HITS_PER_PAGE

  useEffect(() => {
    setPageNumber(1)
  }, [topic, role])

  return (
    <ResultsSection>
      <Wrapper>
        <HitCount content={content} />
        <Grid className="cardGrid" container>
          {noFilter
            ? noPressRealeasePromotedResources?.map((resource) => (
                <FeatureCardVertical
                  body={resource?.resource?.[0]?.short_description}
                  cardType={
                    resource?.resource?.[0]?.resource_type?.[0]?.title ||
                    'Article'
                  }
                  featureImage={resource?.resource?.[0]?.feature_image}
                  headline={resource?.resource?.[0]?.headline}
                  key={resource.id}
                  linkTarget={resource.url}
                />
              ))
            : null}
          {hitsByPageUrl.map((hit, index) =>
            index < pageNumber * HITS_PER_PAGE ? (
              <FeatureCardVertical
                body={hit.short_description}
                cardType="Article"
                featureImage={hit.image}
                headline={hit.headline}
                key={hit.objectID}
                linkTarget={hit.page_url}
              />
            ) : null,
          )}
        </Grid>
        {hasMorePages ? (
          <div className="loadMore">
            <Button
              onClick={() => {
                if (hasMore) {
                  refineNext()
                }

                setPageNumber((prev) => prev + 1)
              }}
              variant="outlined"
            >
              {content.load_more_text}
            </Button>
          </div>
        ) : null}
      </Wrapper>
    </ResultsSection>
  )
}

interface SearchBoxProps extends SearchBoxProvided {
  content: Content
}

const SearchBoxContainer = connectSearchBox<SearchBoxProps>(SearchBox)

const HitsContainer = connectInfiniteHits<HitsProps, BasicDoc>(Hits)

const ResourceSearch: FC<ResourceSearchProps> = ({ content }) => {
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      ),
    [],
  )
  const { siteLocale } = useLocale()

  return (
    <InstantSearch
      indexName={`Resources_${siteLocale.locale}_${process.env.GATSBY_CONTENTSTACK_ENVIRONMENT}`}
      searchClient={searchClient}
    >
      <Configure hitsPerPage={HITS_PER_PAGE} />
      <ResourceSearchSection>
        <Wrapper>
          <SearchBoxContainer
            content={content}
            defaultRefinement={getParam('searchKeyword')}
          />
        </Wrapper>
      </ResourceSearchSection>

      <Index
        indexName={`Resources_${siteLocale.locale}_${process.env.GATSBY_CONTENTSTACK_ENVIRONMENT}`}
      >
        <HitsContainer content={content} />
      </Index>
    </InstantSearch>
  )
}

export default ResourceSearch
