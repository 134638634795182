import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ResourceSearchSection = styled.section`
  padding: 120px 0;
  background-color: ${rspTheme.palette.primary.background};

  .filterSubhead {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 24px;
  }

  .filterContainer {
    justify-content: space-between;

    .MuiFormLabel-root[data-shrink='true'] {
      transform: translate(12px, 10px) scale(0.75);
    }
  }

  .textField {
    width: 100%;
    min-width: 200px;
    margin-bottom: 12px;

    &.textFieldHalf {
      width: 48%;

      @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
        width: 100%;
      }
    }

    &.keywordInput {
      width: unset;
      flex-grow: 1;
    }

    .MuiFilledInput-root {
      background-color: transparent;

      &:focus-within .MuiSelect-root {
        outline: 1px solid ${rspTheme.palette.primary.main};
      }
    }

    .MuiInputBase-input {
      background-color: #ffffff;
      border-radius: 4px;
      margin-bottom: 24px;
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    }

    .MuiFilledInput-input {
      padding: 24px 12px 8px;
    }

    .MuiFilledInput-underline::after {
      border-bottom-color: transparent;
    }

    .MuiFilledInput-underline::before {
      border-bottom-color: transparent;
    }

    .MuiFormControl-root {
      position: relative;
    }

    .MuiFormLabel-root {
      color: ${rspTheme.palette.primary.main};
    }

    .MuiFormHelperText-root {
      position: absolute;
      bottom: 2px;
      letter-spacing: -0.6px;
    }

    .MuiFormHelperText-contained {
      margin-left: 0;
      width: 100%;
    }

    .MuiSelect-iconFilled {
      top: 16px;
    }
  }

  .keywordInputContainer {
    background-color: #ffffff;
    border-radius: 35px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 4px 4px 4px 8px;
    margin-bottom: 32px;
    align-items: center;

    .keywordInput {
      margin-bottom: 0;

      .MuiInputBase-input {
        box-shadow: none;
        background-color: transparent;
        margin-bottom: 0;
        align-self: stretch;
      }
    }

    .clearContainer {
      display: inline-flex;
      height: 48px;
      border-right: 1px solid #f3f1f5;
      align-items: center;
      width: 48px;
      margin-right: 24px;

      .clearButton {
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: center;
        svg {
          color: rgba(0, 0, 0, 0.56);
        }
      }
    }

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      border-radius: 24px;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .filterSubhead {
      font-size: 1.6rem;
      font-weight: 400;
      margin-bottom: 8px;
    }
  }
`

export const SearchButton = styled.button`
  background-color: ${rspTheme.palette.primary.main};
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    width: 50px;
    height: 50px;
  }
`

export const ResultsSection = styled.section`
  padding: 94px 0 72px;

  .headline {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 54px;
  }

  .cardGrid {
    justify-content: space-between;
    gap: 40px;

    @media (min-width: ${rspTheme.mobileNavBreakpoint}) {
      &::after {
        content: '';
        flex: auto;
      }
    }

    > * {
      width: 30%;
      min-width: 300px;
    }
  }

  .loadMore {
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.lg}px) {
    .cardGrid {
      justify-content: center;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .headline {
      font-size: 2.8rem;
      margin-bottom: 32px;
    }
  }
`
